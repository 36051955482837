import { withSentry } from "@sentry/remix";
import {
  json,
  Links,
  Meta,
  type MetaFunction,
  Outlet,
  Scripts,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";
import type { LoaderFunctionArgs } from "@remix-run/node";
import "@radix-ui/themes/styles.css";
import { Theme } from "@radix-ui/themes";
import "./global.scss";
import { ErrorBoundaryComponent } from "@remix-run/react/dist/routeModules";
import { type ReactNode } from "react";
import { appFaviconLink, pageTitle } from "./utils/page-meta";
import { DefaultContent, ErrorLayout } from "./components/errors/ErrorLayout";

// Base title & icon (routes usually override this)
export const meta: MetaFunction = () => {
  return [pageTitle(null), { ...appFaviconLink(), tagName: "link" }];
};

export const loader = (_context: LoaderFunctionArgs) => {
  if (!process.env.SENTRY_DSN) {
    throw new Error("Missing SENTRY_DSN");
  }
  return json({ ENV: { SENTRY_DSN: process.env.SENTRY_DSN } });
};

function App() {
  const data = useLoaderData<typeof loader>();
  return bodyShell(
    <>
      <Outlet />

      <script
        dangerouslySetInnerHTML={{
          __html: `window.ENV = ${JSON.stringify(data.ENV)}`,
        }}
      />
    </>
  );
}

export default withSentry(App);

export const ErrorBoundary: ErrorBoundaryComponent = () => {
  const error = useRouteError() as Error;

  const errorBody: React.ReactNode = <DefaultContent error={error} />;

  return bodyShell(<ErrorLayout children={errorBody} error={error} />);
};

const bodyShell = (children: ReactNode) => {
  return (
    <html lang="en">
      <head>
        <link rel="icon" href="data:image/x-icon;base64,AA" />
        <Links />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
      </head>
      <Theme accentColor="teal" asChild>
        <body>
          {children}
          <Scripts />
        </body>
      </Theme>
    </html>
  );
};
